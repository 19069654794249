<template>
  <div>
    <topbar @toggle-drawer="drawer = !drawer" />

    <v-main>
      <v-container fluid>
        <transition
          :name="$vuetify.rtl ? 'slideRight' : 'slideLeft'"
          mode="out-in"
        >
          <router-view />
        </transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Topbar from '../Layout/Topbar.vue'

export default {
  components: {
    Topbar,
  },

  computed: {
    ...mapGetters(['isLoggedIn']),
  },

  beforeMount() {
    if (!this.isLoggedIn) {
      this.$router.push({ name: 'admin-login' })
    } else {
      if(this.$route.name == 'admin-panel') {
        this.$router.back()
      }
      this.getAuthUserPermission()
      this.getAttachmentTypesList()
    }
  },

  methods: {
    ...mapActions([
      'getAuthUserPermission',
      'getAttachmentTypesList',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>